import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Close from '../../svgs/Close';
import * as styles from './stagingNotice.module.scss';

class StagingNotice extends Component {
  state = {
    timer: null,
    counter: 30,
    show: true,
  };

  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    this.removeTimer();
  }

  setTimer = () => {
    const timer = setInterval(this.tick, 1000);
    this.setState({ timer });
  };

  removeTimer = () => {
    clearInterval(this.state.timer);
    this.setState({ timer: null });
  };

  tick = () => {
    if (this.state.counter === 0) {
      this.setState({
        counter: 30,
      });
      window.location.reload();
    } else {
      this.setState({
        counter: this.state.counter - 1,
      });
    }
  };

  hideOverlay = () => {
    this.setState({ show: false });
    this.removeTimer();
  };

  render() {
    const { counter, show } = this.state;
    const { netlifyBuildTime, layout } = this.props;

    return (
      <div
        className={[styles.stagingNotice, show ? styles.show : styles.hide, styles[layout]].join(
          ' ',
        )}>
        <a href="#hide" aria-label="Close staging notice" className={styles.close} onClick={this.hideOverlay} onKeyDown={this.hideOverlay}>
          <Close />
        </a>
        <div className={styles.content}>
          <h4 className={'title'}>Trying to preview your changes?</h4>
          <p>
            Your last deploy was on <strong>{netlifyBuildTime}</strong>. If you are trying to
            preview changes you just made, they might still be building. You can check the status of
            your deploys{' '}
            <a href="https://app.netlify.com/sites/emcap/deploys" target="_blank" rel="noreferrer">
              <strong>here</strong>
            </a>
            .
          </p>

          <p>
            The page will automatically refresh in <strong>{counter}</strong>.
          </p>
        </div>
      </div>
    );
  }
}

StagingNotice.propTypes = {
  netlifyBuildTime: PropTypes.string,
};

export default StagingNotice;
