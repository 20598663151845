import React from 'react';
import * as styles from './close.module.scss';

const Close = () => (
  <svg
    className={styles.close}
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="58"
    viewBox="0 0 58 58">
    <path d="M29,56 C43.9116882,56 56,43.9116882 56,29 C56,14.0883118 43.9116882,2 29,2 C14.0883118,2 2,14.0883118 2,29 C2,43.9116882 14.0883118,56 29,56 Z M28.8994949,27.4852814 L20.4142136,19 L19,20.4142136 L27.4852814,28.8994949 L19,37.3847763 L20.4142136,38.7989899 L28.8994949,30.3137085 L37.3847763,38.7989899 L38.7989899,37.3847763 L30.3137085,28.8994949 L38.7989899,20.4142136 L37.3847763,19 L28.8994949,27.4852814 Z M29,58 C12.9837423,58 0,45.0162577 0,29 C0,12.9837423 12.9837423,0 29,0 C45.0162577,0 58,12.9837423 58,29 C58,45.0162577 45.0162577,58 29,58 Z" />
  </svg>
);

export default Close;
