/**
 * Convert Object to a List
 * @param {Obj} object
 */
module.exports.objectToList = object => {
  return object ? Object.keys(object).map(key => ({ ...object[key], index: key })) : [];
};

/**
 * Separates string by pipe delimiter
 * @param {Str} string
 */
module.exports.explodeText = string => {
  const matches = string.match(new RegExp(/(\S.*)\|(\S.*)/));
  return {
    label: matches[1],
    value: matches[2],
  };
};

/**
 * Sort an article/press array by date
 * @param {Arr} arr
 */
module.exports.sortByDate = arr => {
  return arr.sort((a, b) => {
    let dateA = new Date(a.publishedDate);
    let dateB = new Date(b.publishedDate);
    return dateB - dateA;
  });
};

/**
 * Strip HTML from the string
 * @param {Str} string
 */
module.exports.stripHtml = string => {
  if (!string) {
    return '';
  }
  return string.replace(/<[^>]*>?/gm, '');
};
