import React from 'react';
import Helmet from 'react-helmet';
import * as PropTypes from 'prop-types';

import { graphql } from 'gatsby';

const SEO = ({ siteData, seoData, defaultTitle, pathName, type }) => {
  const url = siteData && `${siteData.siteMetadata.siteUrl}${pathName}`;
  const metaTitle = seoData && seoData.customTitle ? seoData.customTitle : defaultTitle;
  const title = type === 'article' ? `Emergence - ${metaTitle}` : metaTitle;
  const description =
    seoData && seoData.metaDescription
      ? seoData.metaDescription
      : 'Emergence is a top enterprise cloud venture capital firm. We fund early stage ventures focusing on enterprise &amp; SaaS applications. Emergence is one of the top VC firms in Silicon Valley.';
  const image =
    seoData && seoData.shareImage && seoData.shareImage.file
      ? `https:${seoData.shareImage.file.url}`
      : 'https://emcap.com/images/og-image.jpg';
  return (
    <Helmet>
      {/* Primary meta tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Open Graph/Facebook meta tags */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      {/* Twitter meta tags */}
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={metaTitle} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
    </Helmet>
  );
};

SEO.propTypes = {
  siteData: PropTypes.object.isRequired,
  seoData: PropTypes.object,
  defaultTitle: PropTypes.string.isRequired,
  pathName: PropTypes.string,
  type: PropTypes.string,
};

export default SEO;

export const query = graphql`
  fragment SiteQuery on Query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
